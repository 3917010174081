import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ClientServicesPage = (props) => (
  <Layout location={props.location}>
    <SEO title="Client Services" />
    <h1 style={styles.header}>Client Services</h1>
    <div style={styles.body}>
      <h3 style={{...styles.italic, ...styles.primaryText}}>Client Services That Are All About You.</h3>
      <p>
        At the Coastal Advisory Group, our team of specialists can help manage your complete financial life. Our 
        team brings together all the elements that put you on course for the future. Our planning and investment 
        management services include:
      </p>
      <ul>
        <li>
          <span style={{fontWeight: 600}}>Multiple Generational Wealth Strategies</span> that strive to create the legacy you want to leave, provide tax 
          advantages now and in the future, and protects your heirs.
        </li>
        <li>
          <span style={{fontWeight: 600}}>Team-based Estate Planning</span> that helps you define all of your objectives prior to meeting with an attorney. 
          We then work with you and your attorney, CPA and other advisors to try to accomplish your goals. If you 
          are not represented, we research your unique situation to help you locate an attorney or CPA with the 
          specific expertise you need.
        </li>
        <li>
          <span style={{fontWeight: 600}}>Business and Tax Planning</span> to help you gain tax advantages for both you and your business.
        </li>
        <li>
          <span style={{fontWeight: 600}}>Business Succession and Protection Planning</span> that allows you to rest easier. We work to help you try to ensure 
          that your company’s future leadership role is in place according to your wishes and that the most sophisticated 
          strategies can be employed to sell your business and reduce capital gains.
        </li>
        <li>
          <span style={{fontWeight: 600}}>College Planning</span> that goes beyond financial planning to include tax advantages for you. We help you select 
          the school where the student you are sponsoring may have the greatest chance of success, and help you plan 
          the financial help that will be needed.
        </li>
      </ul>
      <a href="/contact">Let Six Year Five Star Award Winner and President, Michael Mueller, and our team of specialists make a difference to your financial life today!</a>
    </div>
  </Layout>
)

let styles={
  header: {
    color: '#8d1b38',
    padding: '35px 15px 0 25px'
  },
  primaryText: {
    color: '#8d1b38'
  },
  bullet: {
    paddingLeft: '30px'
  },
  secondaryText: {
    color: '#083f95',
    fontWeight: '700'
  },
  italic: {
    fontStyle: 'italic'
  },
  body: {
    padding: '0 60px 30px 60px',
    color: 'black'
  }
}

export default ClientServicesPage
